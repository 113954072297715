@import "../../../data/styles.css";

.works-body {
    margin-bottom: 10px;
}

.work {
    display: flex;
    flex-direction: column; /* Changed flex direction to column */
    align-items: center; /* Center aligning items */
    padding-bottom: 40px;
    width: 400px;
    position: relative; /* Added for positioning child elements */
}

.work-image {
    height: 80px; /* Adjusted height for better visibility */
    width: 80px; /* Adjusted width for better visibility */
    border-radius: 50%;
    outline: 6px solid white;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px; /* Added margin to separate from other elements */
}

.work-title {
    font-size: 15px;
    font-weight: 700;
    padding-left: 20px;
    margin-top: -3px;
    color: var(--secondary-color);
}

.work-subtitle {
    font-size: 12px;
    color: var(--secondary-color);
    padding-top: 10px; /* Adjusted padding */
    text-align: center; /* Center aligned */
    width: 300px; /* Added width to prevent overlapping */
}

.work-duration {
    width: 350px;
    font-size: 12px;
    padding-top: 10px; /* Adjusted padding */
    text-align: center; /* Center aligned */
    color: var(--tertiary-color);
}
